// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComboBox_option__qsuDe {
    &[data-combobox-selected] {
      background-color: #2A3942;
      font-weight: 500;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Chat/header/ComboBox.module.css"],"names":[],"mappings":"AAAA;IACI;MACE,yBAAyB;MACzB,gBAAgB;IAClB;EACF","sourcesContent":[".option {\n    &[data-combobox-selected] {\n      background-color: #2A3942;\n      font-weight: 500;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": `ComboBox_option__qsuDe`
};
export default ___CSS_LOADER_EXPORT___;
