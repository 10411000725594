function NoVideoIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      className={className}
      version="1.1"
      viewBox="0 0 520 520"
      xmlSpace="preserve"
    >
      <g>
        <path d="M505.29 157.622c-9.005-5.568-20.585-6.075-30.037-1.342L397 195.244v-42.185c0-16.862-13.256-30.136-30.118-30.136H183.734l-68.365-80.99c-10.883-12.866-30.131-14.591-43.027-3.685C59.476 49.14 57.866 68.36 68.764 81.233l335.867 396.909c6.038 7.134 14.641 10.797 23.318 10.797 6.962 0 13.97-2.377 19.71-7.23 12.866-10.891 14.276-30.164 3.378-43.038L397 375.045v-19.903l78.136 38.964c4.309 2.154 9.098 3.22 13.764 3.22 5.576 0 11.435-1.528 16.34-4.562 8.99-5.561 14.76-15.386 14.76-25.971v-183.2c0-10.586-5.72-20.41-14.71-25.971zM0 153.059v244.267c0 16.862 14.138 30.597 31 30.597h260.756L29.879 122.647C13.443 123.128 0 136.499 0 153.059z"></path>
      </g>
    </svg>
  );
}

export default NoVideoIcon;
